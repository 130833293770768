<template>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page.sync="currentPage"
    :page-size="pageSize"
    :total="total"
    :page-sizes="[10, 20, 30]"
    :small="small"
    layout="total, sizes, prev, pager, next, jumper"
    class="pagination-box"
  />
</template>

<script>
export default {
  props: {
    // 当前页数
    pageIndex: {
      type: Number,
      default: 1,
    },
    // 当前条数
    pageSize: {
      type: Number,
      default: 10,
    },
    // 总条数
    total: {
      type: Number,
      default: 0,
    },
    // 是否使用小型分页样式
    small: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPage: 1, // 页数  默认第一页
    }
  },

  watch: {
    pageIndex(val, oldVal) {
      this.currentPage = val
    },
  },

  methods: {
    // 修改页数
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.$emit('handleCurrentChange', val)
    },
    // 修改当前页条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.$emit('handleSizeChange', val)
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination-box {
  text-align: center;
  margin-top: 15px;
}
</style>
