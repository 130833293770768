<template>
  <el-dialog
    width="888px"
    :visible.sync="visible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    @open="openDialog"
    top="2vh"
    append-to-body
  >
    <div slot="title" class="title">选择成员</div>

    <el-row :gutter="20" style="height: 500px">
      <el-col :span="8" style="border-right: 1px solid #eee; height: 100%; overflow-y: auto">
        <el-tree
          :data="dept_data"
          :props="defaultProps"
          node-key="dept_id"
          default-expand-all
          highlight-current
          @node-click="handleNodeClick"
        ></el-tree>
      </el-col>
      <el-col
        :span="8"
        style="
          border-left: 1px solid #eee;
          margin-left: -1px;
          height:100%;
          overflow:hidden;
        "
        v-loading.lock="loading"
      >
        <template v-if="userListByDept.length > 0">
          <el-input
            placeholder="输入关键字进行过滤"
            v-model="filterText"
            clearable
            @keyup.enter.native="onSearch"
            @clear="onSearch"
            size="small"
          >
            <el-select
              style="width:80px"
              v-model="gender"
              slot="prepend"
              placeholder="请选择"
              @change="handleGenderChange"
            >
              <el-option label="全部" :value="-1"></el-option>
              <el-option label="保密" :value="0"></el-option>
              <el-option label="男" :value="1"></el-option>
              <el-option label="女" :value="2"></el-option>
            </el-select>
            <el-button slot="append" icon="el-icon-search" @click="onSearch" />
          </el-input>

          <div
            style="height:calc(100% - 42px);overflow-y:auto;margin:10px 0 0;"
            v-if="userList.length > 0"
          >
            <template v-if="isMutiple">
              <div style="padding:10px 0;">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="allChecked"
                  @change="handleAllCheck"
                >全选</el-checkbox>
              </div>
              <el-checkbox-group v-model="checkedListID" @change="handleCheckedListChange">
                <div v-for="user in userList" :key="user.id" style="margin:0 0 5px;">
                  <el-checkbox
                    :label="user.id"
                    @change="handleUserChange($event, user)"
                  >{{ user.name }}</el-checkbox>
                </div>
              </el-checkbox-group>
            </template>
            <template v-else>
              <el-radio-group v-model="checkedListID" @change="handleCheckedListChange">
                <div v-for="user in userList" :key="user.id" style="margin:0 0 5px;">
                  <el-radio
                    :label="user.id"
                    @change="handleUserChange($event, user)"
                  >{{ user.name }}</el-radio>
                </div>
              </el-radio-group>
            </template>
          </div>
          <el-empty v-else :image-size="200" description="没有相关数据" />
        </template>
        <template v-else>
          <el-empty :image-size="200" />
        </template>
      </el-col>
      <el-col
        :span="8"
        style="
          border-left: 1px solid #eee;
          margin-left: -1px;
          height: 100%;
          overflow-y: auto;
        "
      >
        <div v-for="item in checkedList" :key="item.id" style="margin:0 0 5px;">
          <el-tag closable @close="handleTagClose(item)">{{ item.name }}</el-tag>
        </div>
      </el-col>
    </el-row>

    <div slot="footer">
      <el-button class="footer-btn footer-btn-cancel" size="small" @click="handleClose(false)">取 消</el-button>
      <el-button
        class="footer-btn"
        :loading="submitLoading"
        type="primary"
        size="small"
        @click="handleSubmit"
      >保 存</el-button>

      <div class="checked-tips">
        <span v-if="checkedList.length > 0">
          已选中
          <strong>{{ checkedList.length }}</strong> 人
        </span>
        <span v-else>暂无选中任何人</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import api from '../api'
import _ from 'lodash'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Array,
      default: () => [],
    },
    isMutiple: {
      type: Boolean,
      default: true,
    },
    isAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'dept_name',
      },
      dept_data: [],
      filterText: '',
      submitLoading: false,
      userListByDept: [],
      userList: [],
      checkedList: [],
      loading: false,
      allChecked: false,
      isIndeterminate: true,
      checkedListID: [],  // checkbox group value
      gender: -1,
      genderStr: ['保密', '男', '女'],
    }
  },
  methods: {
    handleGenderChange(v) {
      if (v === -1) {
        this.userList = this.userListByDept
      } else {
        this.userList = this.userListByDept.filter(item => item.gender === v)
      }

      const arr = _.intersectionBy(this.checkedList, this.userList, 'id')
      this.allChecked = arr.length === this.userList.length
      this.isIndeterminate = arr.length !== this.userList.length


    },
    handleTagClose(tag) {
      this.checkedList.splice(this.checkedList.indexOf(tag), 1);

      const arr = _.intersectionBy(this.checkedList, this.userList, 'id')
      //  this.userList = arr
      this.allChecked = arr.length === this.userList.length


      const index = this.checkedListID.indexOf(tag.id)
      index >= 0 && this.checkedListID.splice(index, 1)
    },
    onSearch() {
      this.userList = this.userListByDept.filter((item) => {
        return item.name.indexOf(this.filterText) >= 0
      })
    },
    handleAllCheck(v) {
      if (v) {
        this.isIndeterminate = false
        this.checkedList = _.unionBy(this.checkedList, this.userList, 'id')

        this.checkedListID = this.userList.map(item => item.id)
      } else {
        this.isIndeterminate = true
        _.pullAllBy(this.checkedList, this.userList, 'id')
        this.checkedListID = []
      }
    },
    getData() {
      api.common.deptQuery({}).then((res) => {
        if (res.code !== 0) this.$message.error(res.message);
        this.dept_data = res.data
      })
    },
    handleUserChange(v, user) {
      if (this.isMutiple) {
        if (v) {
          // 选中
          this.checkedList = _.unionBy(
            [...this.checkedList, { id: user.id, name: user.name }],
            'id'
          )
        } else {
          // 取消
          const index = this.checkedList.findIndex((d) => d.id === user.id)
          index >= 0 && this.checkedList.splice(index, 1)

        }

        const arr = _.intersectionBy(this.checkedList, this.userList, 'id')
        this.allChecked = arr.length === this.userList.length
        this.isIndeterminate = arr.length !== this.userList.length
      } else {
        this.checkedList = [user]
      }

    },
    // isInValues(id) {
    //     return this.checkedList.findIndex((d) => d.id === id) >= 0
    // },
    handleCheckedListChange() { },
    async handleNodeClick(data, node) {
      this.filterText = ''
      this.loading = true
      try {
        let res = []
        if (this.isAll) {
          res = await api.common
            .getAllUserByDept({
              dept_id: data.dept_id,
            })
        } else {
          res = await api.common
            .getFaceUserByDept({
              dept_id: data.dept_id,
            })
        }

        if (res.code !== 0) this.$message.error(res.message);
        this.loading = false
        if (res.data) {
          let data = res.data.map((item) => {
            return { id: item.user_id, name: item.nick_name, gender: item.gender }
          })
          if (this.gender > 0) {
            data = data.filter(item => item.gender === this.gender)
          }
          this.userListByDept = data
          this.userList = data

          const arr = _.intersectionBy(this.checkedList, data, 'id')

          this.allChecked = arr.length === data.length
          this.isIndeterminate = arr.length !== data.length

          this.checkedListID = arr.map(item => item.id)


        } else {
          this.userListByDept = []
          this.userList = []
        }
      } catch (error) {
        this.$message.error("网络故障，请稍后再试")
        this.userListByDept = []
        this.userList = []
      }

    },
    reset() {
      this.checkedList = []
      this.userList = []
      this.userListByDept = []
      this.allChecked = false
      this.filterText = ''
    },
    handleSubmit() {
      this.$emit('handleClose', this.checkedList)
      this.reset()
    },
    handleClose() {
      this.reset()
      this.$emit('handleClose', false)
    },
    openDialog() {
      this.checkedList = this.values
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

::v-deep .el-dialog .el-dialog__body {
  padding: 15px !important;
}

.checked-tips {
  float: left;
  line-height: 32px;
}
</style>

.title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

::v-deep .el-dialog .el-dialog__body {
  padding: 15px !important;
}

.checked-tips {
  float: left;
  line-height: 32px;
}
